<template>
  <div>
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left align-self-center>{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <PageLoader v-bind:storage="appLoading" />
    <ServerError v-if="ServerError" />
    <v-dialog
      v-model="packageConfirmationDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <PackageConfirmation
        v-bind:storage="storage.cart[0].quantity"
        @stepper="winStepper"
      />
    </v-dialog>
    <v-layout wrap>
      <v-flex xs12>
        <v-btn
          @click="validateInput"
          depressed
          small
          block
          dark
          :ripple="false"
          color="#53A874"
        >
          <span class="poppinsbold"> CHECKOUT</span>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import PackageConfirmation from "./../../../components/Common/packageConfirmation";
export default {
  props: ["totalAmount", "storage","deliveryId"],
  components: {
    PackageConfirmation,
  },
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      payId: "",
      packageConfirmationDialog: false,
      vehicles: [],
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
      // return this.$store.state.userData.guest;
    },
  },
  mounted() {
    this.vehicles = [];
    // for (var i = 0; i < this.storage.length; i++) {
    //   for (var j = 0; j < this.storage[i].vehicles.length; j++) {
    //     this.vehicles.push(this.storage[i].vehicles[j]);
    //   }
    // }
  },
  methods: {
    validateInput() {
      if (this.guestCount < this.totalGuest) {
        this.msg = "Please select all guests";
        this.showSnackBar = true;
        return;
      }
      this.checkOut()
      // this.packageConfirmationDialog = true;
    },
    winStepper(windowData) {
      this.packageConfirmationDialog = windowData.dialog;
      if (windowData.accept) this.checkOut();
    },
    checkOut() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/purchase/checkout",
        data: {
          amount: this.totalAmount,
          deliveraddressid: this.deliveryId,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            window.id = response.data.paymentdata._id;
            window.orderid = response.data.data.order_id;
            
            window.location.href=response.data.redirecturl

            // var options = {
            //   key: "rzp_live_6tBA1QlJeEv8qF",
            //   // key: "rzp_test_hOMmQHmHSDvzgV",
            //   amount: Number(this.totalAmount * 100),
            //   name: this.userData.name,
            //   currency: "INR",
            //   description: "PARAMBIKULAM",
            //   image: "https://jolly-volhard-bc2f0b.netlify.com/favicon.ico",
            //   order_id: orderid,

            //   handler: function (response) {
            //     // location.href =
            //     //   "http://parambikulam.org/planYourVisit/paywaitScreen/" +
            //     //   id +
            //     //   "/" +
            //     //   orderid;
            //     location.href =
            //       "https://www.parambikulam.org/Shopping/PaywaitScreenC/" +
            //       id +
            //       "/" +
            //       orderid;
            //   },

            //   prefill: {
            //     name: this.userData.name,
            //     email: this.userData.email,
            //     contact: this.userData.phoneno,
            //   },

            //   theme: {
            //     color: "#53a874",
            //   },
            //   // modal: {
            //   //   ondismiss: function () {
            //   //     location.href = "https://parambikulam.org/";
            //   //   },
            //   // },
            //   // callback_url:
            //   //   "http://parambikulam.org/planYourVisit/paywaitScreen?id =" +
            //   //   response.data.paymentdata.txnid,
            //   // redirect: true,
            // };
            // var rzp1 = new Razorpay(options);
            // rzp1.open();
            // this.payid = response.data.donationdata._id;
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
          this.ServerError = true;
        });
    },
  },
};
</script>
