<template>
  <div>
    <v-layout wrap justify-center v-if="cartData" pa-2>
      <v-flex xs12 sm6 pr-sm-2 pr-md-0 md6 lg5 xl4 align-self-start v-if="appUser">
        <ShipAddress :appUser="appUser" @stepper="winStepper" />
      </v-flex>
      <v-flex xs12 sm6 md6 lg4 pr-md-2 v-if="summary && state" align-self-start>
        <v-card flat style="background-color: #f7f7f7; min-height: 20vh">
          <v-layout wrap justify-center px-4 pt-5>
            <v-flex xs12 align-self-center py-3>
              <span style="font-family: poppinsbold; font-size: 20px"
                >₹ Product Details </span
              ><br />
              <v-divider></v-divider>
            </v-flex>
            <v-flex text-left xs12 py-2 v-if="cartData">
              <v-layout wrap>
                <v-flex xs12 py-2 v-for="(item, i) in cartData" :key="i">
                  <v-layout wrap justify-start>
                    <v-flex xs2 lg1 align-self-start pt-lg-1>
                      <v-img
                        v-if="item.product.images.length > 0"
                        :src="mediaURL + item.product.images[0]"
                        width="100%"
                        contain
                      >
                        <template v-slot:placeholder>
                          <ImageLoader />
                        </template>
                      </v-img>
                    </v-flex>
                    <v-flex xs8 md8 lg9 px-2 align-self-start>
                      <v-layout wrap justify-start>
                        <v-flex xs12 align-self-start>
                          <span class="content"
                            ><b> {{ item.product.name }}</b></span
                          >
                        </v-flex>
                        <v-flex xs12 align-self-start style="color: #53a874">
                          <span v-if="item.unitname" class="content">
                            {{ item.unitname }}</span
                          >
                          <span v-if="item.sizename" class="content">
                            - {{ item.sizename }}</span
                          >
                        </v-flex>
                        <!-- <v-flex xs12 align-self-start style="color: #000">
                          
                          <span v-if="item.productPrice" class="content">
                            Rs. {{ item.productPrice }}
                          </span>
                        </v-flex> -->
                      </v-layout>
                    </v-flex>
                    <v-flex xs2 lg2 align-self-start text-center>
                      <v-card
                        tile
                        class="content"
                        style="border: 1px solid #53a874; font-size: 13px"
                        flat
                        outlined
                        ><b>
                          <span>Qty - </span>
                          <span>{{ item.quantity }}</span></b
                        >
                      </v-card>
                    </v-flex>
                    <v-flex lg1></v-flex>
                    <v-flex
                      px-2
                      xs8
                      lg9
                      align-self-start
                      style="font-size: 14px; color: #000"
                    >
                      <span v-if="item.productPrice" class="content">
                        Product Total</span
                      >
                    </v-flex>
                    <v-flex
                      xs4
                      lg2
                      align-self-start
                      style="font-size: 14px; color: #000"
                    >
                      <span v-if="item.productTotalPrice" class="content">
                        Rs. {{ item.productTotalPrice }}
                      </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
          <v-layout wrap justify-center px-4 pt-5>
            <v-flex xs12 align-self-center py-3>
              <span style="font-family: poppinsbold; font-size: 20px"
                >₹ Pricing Details </span
              ><br />
              <v-divider></v-divider>
            </v-flex>
            <v-flex text-left xs8 py-2 v-if="summary.cart">
              <span class="content"
                >Price ({{ summary.cart.length }} Item<span
                  v-if="summary.cart.length > 1"
                  >s</span
                >)</span
              >
            </v-flex>
            <v-flex text-end xs4 py-2>
              <span class="content">₹ {{ summary.price }}</span>
            </v-flex>
            <v-flex text-left xs8 py-2>
              <span class="content">Payment Gateway charge</span>
            </v-flex>
            <v-flex text-end xs4 py-2>
              <span class="content">₹ {{ summary.paymentgatewayCharge }}</span>
            </v-flex>
            <!-- <v-flex text-left xs8 py-2>
              <span class="content">Shipping charge</span>
            </v-flex>
            <v-flex text-end xs4 py-2>
              <span class="content">₹ {{ summary.deliveryCharge }}</span>
            </v-flex> -->
          </v-layout>
          <v-layout
            wrap
            justify-center
            px-4
            py-3
            style="font-family: poppinsbold; font-size: 16px; color: #53a874"
          >
            <v-flex xs6 text-left> TOTAL AMOUNT </v-flex>
            <v-flex xs6 text-right align-self-end>
              {{ totalAmount }} INR
            </v-flex>
          </v-layout>

          <v-layout wrap v-if="totalAmount > 0 && summary.cart" pa-4>
            <v-flex xs12 pb-6>
              <CheckOut
                v-bind:totalAmount="totalAmount"
                v-bind:storage="summary"
                v-bind:deliveryId="delivery"
                :key="summary.finalAmount"
              />
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <!-- <v-flex align-self-center text-center v-else-if="!state">
        <span class="poppinsregular">Please provide delivery address</span>
      </v-flex> -->
      <v-flex align-self-center text-center v-else-if="state">
        <span class="poppinsregular">Your Cart is empty!</span>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import CheckOut from "./checkOut";
import ShipAddress from "./addressDetails";
export default {
  components: {
    CheckOut,
    ShipAddress,
  },
  data() {
    return {
      cartData: [],
      summary: [],
      totalAmount: null,
      state: null,
      delivery: null,
      appUser:null
    };
  },
  
  beforeMount() {
    // this.getData();
    this.getUser();
    
  },
  methods: {
    winStepper(window_data) {
      if (window_data.state) {
        console.log("State", window_data.state);
        this.delivery = window_data.deliveryAddressId;
        this.state = window_data.state;
        this.getData();
      }
    },
    getData() {
      this.cartData = [];
      this.summary = [];
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/productcart/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: { state: this.state },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.cartData = response.data.data;
            this.summary = response.data.summary;
            this.totalAmount = this.summary.finalAmount;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getUser() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/user/me/product",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.appUser = response.data.data;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // formatDate(item) {
    //   var dt = new Date(item);
    //   var day = dt.getDate();
    //   var year = dt.getFullYear();
    //   dt = dt.toString();
    //   var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
    //   return strTime;
    // },
    formatDate(item) {
      // var dt = new Date('2014-8-20');
      var dt = new Date(item);
      // var a=dt.toISOString()
      // console.log("aa",a)
      // console.log("init",dt)
      // var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toUTCString();
      var strTime = dt.slice(4, 11) +  " " + year + " ";
      // var strTime = dt.slice(4, 7) + " " + day + " " + year + " ";
      // console.log("dd",dt)
      // console.log("bef4",item)
      return strTime;
    },
  },
};
</script>

<style>
.vehicleSelector .v-input__slot {
  background-color: #f7f7f7 !important;
}
</style>